import {
  IconDefinition,
  faBusinessTime,
  faLayerGroup,
  faListOl,
  faPortrait,
  faSignalStream,
  faStar,
  faUserFriends,
} from '@fortawesome/pro-solid-svg-icons';
import { environment } from '../../../../environments/environment';

const HTTP_BASE_PREFIX = `${environment.apiDomain}`;

export const NOTIFICATION_ENDPOINTS = {
  archived: `${HTTP_BASE_PREFIX}/notification/archivedMessagesForHub`,
  notifications: `${HTTP_BASE_PREFIX}/notification/message`,
  notificationByConcatKey: `${HTTP_BASE_PREFIX}/notification/header/HUB/CONCATKEY/`,
  subscriptions: `${HTTP_BASE_PREFIX}/notification/subscription/`,
  timezones: `${HTTP_BASE_PREFIX}/notification/timeZone/all`,
  userTimezone: `${HTTP_BASE_PREFIX}/notification/timeZone`,
  validNumber: `${HTTP_BASE_PREFIX}/notification/sms/validNumber`,
  simpleValidation: `${HTTP_BASE_PREFIX}/notification/sms/simpleValidation`,
  countryCodeList: `${HTTP_BASE_PREFIX}/notification/sms/countryCodeList`,
  allPhoneNumbers: `${HTTP_BASE_PREFIX}/notification/sms/allNumbers`,
  removeNumber: `${HTTP_BASE_PREFIX}/notification/sms/removeNumber`,
  allowedUser: `${HTTP_BASE_PREFIX}/notification/sms/availableCountries`,
  optOut: `${HTTP_BASE_PREFIX}/notification/sms/opt`,
  optIn: `${HTTP_BASE_PREFIX}/notification/sms/opt/in`,
  resendChallengeCode: `${HTTP_BASE_PREFIX}/notification/sms/resend/challengeCode`,
};

export const NOTIFICATION_ICON_MAP: { [key: string]: IconDefinition } = {
  ORDERS: faListOl,
  TEAM_BUILDING: faUserFriends,
  RECOGNITION: faStar,
  CUSTOMER_SERVICE: faPortrait,
  MY_BUSINESS: faBusinessTime,
  TRAINING: faLayerGroup,
  MARKETING: faSignalStream,
};

export const NOTIFICATION_TRANSLATION_TAGS = {
  markAllAsRead: 'notifications.landing.markAllAsRead',
  markAllAsUnread: 'notifications.landing.markAllAsUnread',
  notificationSettings: 'notifications.landing.notificationSettings',
  viewAll: 'notifications.landing.viewAllNotifications',
  widgetTitle: 'notifications.landing-widget-title',
};
