import { NotificationState } from '@common/notifications/state/notification.state';
import { NotificationStateModel } from '@common/notifications/state/notification.state.model';
import { Selector } from '@ngxs/store';
import { Notification } from '../notification.model';

export class NotificationSelectors {
  @Selector([NotificationState])
  public static getCurrentNotifications(state: NotificationStateModel): Notification[] {
    return state?.currentNotifications.filter((notification) => !notification.deleted);
  }

  @Selector([NotificationSelectors.getCurrentNotifications])
  public static getFirstTenNotifications(notifications: Notification[]) {
    return notifications?.slice(0, 10);
  }

  @Selector([NotificationSelectors.getCurrentNotifications])
  public static getUnreadNotificationCount(notifications: Notification[]) {
    return notifications?.filter((notification: Notification) => !notification.read).length || 0;
  }

  @Selector([NotificationState])
  public static getSingleNotification(state: NotificationStateModel) {
    return state?.singleNotification;
  }

  @Selector([NotificationState])
  public static getArchivedNotifications(state: NotificationStateModel) {
    return state?.archivedNotifications;
  }

  @Selector([NotificationState])
  public static getInterruptNotifications(state: NotificationStateModel) {
    return state?.interruptNotifications.filter((notification) => !notification.read);
  }

  @Selector([NotificationState])
  static loginInterruptOptIn(state: NotificationStateModel) {
    return state?.phoneNumber;
  }
}
