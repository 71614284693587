import { Injectable, Type } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  UniversalModalOptions,
  UniversalModalPosition,
  UniversalModalService,
} from '@usana/ux/universal-components/modal';

@Injectable({
  providedIn: 'root',
})
export class NotificationModalService {
  activeModal: NgbModalRef;

  constructor(private uModalService: UniversalModalService) {}

  openNotificationModal<M>(modalComponentType: Type<M>, args?: any): void {
    this.closeActiveModal();

    const modalOptions: UniversalModalOptions = {
      modalPosition: UniversalModalPosition.RIGHT,
      data: args,
    };

    this.activeModal = this.uModalService.openCustom<M>(modalOptions, modalComponentType);
  }

  closeActiveModal(): void {
    this.activeModal?.close();
  }
}
