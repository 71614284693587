import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NOTIFICATION_ENDPOINTS, NOTIFICATION_TRANSLATION_TAGS } from '@common/notifications/notification.constants';
import { Notification, SinglePhoneNumber } from '@common/notifications/notification.model';
import { MarkAsRead, MarkAsUnread } from '@common/notifications/state/notification.actions';
import { Store } from '@ngxs/store';
import { WINDOW_TOKEN } from '@usana/ux/common';
import { DropdownButtonMenuItem } from '@usana/ux/universal-components/button';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TranslationMap } from '../translation/models/translation-map';
import { TranslationService } from '../translation/translation.service';
import { NotificationModalService } from './services/notification-modal.service';

@Injectable()
export class NotificationService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private translateService: TranslationService,
    private notificationModalService: NotificationModalService,
    @Inject(WINDOW_TOKEN) private window: any,
  ) {}

  private static handleError(error: HttpErrorResponse) {
    return throwError(error.error.message);
  }

  getArchivedNotifications(): Observable<Notification[]> {
    const startDate = new Date();
    const endDate = new Date();

    startDate.setMonth(startDate.getMonth() - 3);
    endDate.setDate(endDate.getDate() - 14);

    return this.http.get(NOTIFICATION_ENDPOINTS.archived).pipe(
      map((response: Notification[]) =>
        this.sortNotificationsByDate(response).map((notification) => {
          notification.deleted = true;
          return notification;
        }),
      ),
    );
  }

  getLoginInterruptNotifications(): Observable<Notification[]> {
    return this.http
      .get(NOTIFICATION_ENDPOINTS.notifications, {
        params: {
          expired: 'false',
          unread: 'true',
          deleted: 'false',
          channels: 'LOGIN_INTERRUPT',
        },
      })
      .pipe(map((response: Notification[]) => this.sortNotificationsByDate(response)));
  }

  getNotificationByConcatKey(key: string): Observable<Notification> {
    return this.http
      .get(NOTIFICATION_ENDPOINTS.notificationByConcatKey + key)
      .pipe(map((response: Notification) => response));
  }

  getCurrentNotifications(): Observable<Notification[]> {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 14);

    return this.http
      .get(NOTIFICATION_ENDPOINTS.notifications, {
        params: {
          startDateAfter: startDate.toISOString(),
          deleted: 'false',
          subscribedOnly: 'true',
          channels: 'HUB',
        },
      })
      .pipe(map((response: Notification[]) => this.sortNotificationsByDate(response)));
  }

  updateNotifications(notifications: Notification[]): Observable<Notification[]> {
    return this.http
      .put(NOTIFICATION_ENDPOINTS.notifications, notifications, {
        params: {
          channel: 'HUB',
        },
      })
      .pipe(map((response: Notification[]) => this.sortNotificationsByDate(response)));
  }

  loginInterruptOptIn(): Observable<SinglePhoneNumber> {
    return this.http.post(NOTIFICATION_ENDPOINTS.optIn, '').pipe(
      map((phoneNumber: SinglePhoneNumber) => phoneNumber),
      catchError(NotificationService.handleError),
    );
  }

  sortNotificationsByDate(notifications: Notification[]) {
    if (!notifications) {
      return [];
    }

    return notifications.sort((curr, next) => curr.millisSinceNotification - next.millisSinceNotification);
  }

  getDropdownMenuItems(limit: boolean, displayedNotifications: Notification[]): Observable<DropdownButtonMenuItem[]> {
    const translations = [
      NOTIFICATION_TRANSLATION_TAGS.markAllAsRead,
      NOTIFICATION_TRANSLATION_TAGS.markAllAsUnread,
      NOTIFICATION_TRANSLATION_TAGS.notificationSettings,
      NOTIFICATION_TRANSLATION_TAGS.viewAll,
    ];

    const translatedValues$ = from(this.translateService.getTranslationListPromise(translations)).pipe(
      map((translatedValues: TranslationMap) => [
        {
          id: 0,
          text: translatedValues[NOTIFICATION_TRANSLATION_TAGS.markAllAsRead].value,
          action: () => this.markAsRead(displayedNotifications),
          hideWhen: () => !displayedNotifications.length,
        },
        {
          id: 1,
          text: translatedValues[NOTIFICATION_TRANSLATION_TAGS.markAllAsUnread].value,
          action: () => this.markAsUnread(displayedNotifications),
          hideWhen: () => !displayedNotifications.length,
        },
        {
          id: 2,
          text: translatedValues[NOTIFICATION_TRANSLATION_TAGS.notificationSettings].value,
          action: () => this.navigateToSettingsPage(),
        },
        {
          id: 3,
          text: translatedValues[NOTIFICATION_TRANSLATION_TAGS.viewAll].value,
          action: () => this.navigateToNotificationPage(),
          hideWhen: () => !limit,
        },
      ]),
    );

    return translatedValues$;
  }

  markAsRead(notifications: Notification[]) {
    return this.store.dispatch(new MarkAsRead(notifications));
  }

  markAsUnread(notifications: Notification[]) {
    return this.store.dispatch(new MarkAsUnread(notifications));
  }

  navigateToNotificationPage(): void {
    this.router.navigate(['notification']);
    this.notificationModalService.closeActiveModal();
  }

  navigateToSettingsPage(): void {
    this.window.open(`https://sso.${environment.DEPLOY_SITEDOMAIN}/account/settings/notifications`);
    this.notificationModalService.closeActiveModal();
  }
}
