import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  pure: true,
})
export class ReplacePipe implements PipeTransform {
  transform(input: string, replacedVal: string, withVal: string) {
    input = input || '';
    replacedVal = replacedVal || '';
    withVal = withVal || '';
    return input.replace(new RegExp(replacedVal, 'g'), withVal);
  }
}
