import { Component } from '@angular/core';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { Notification, SinglePhoneNumber } from '@common/notifications/notification.model';
import { NotificationService } from '@common/notifications/notification.service';
import { MarkAsRead, UpdatePhoneNumber } from '@common/notifications/state/notification.actions';
import { NotificationSelectors } from '@common/notifications/state/notification.selectors';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { Observable } from 'rxjs';

@Component({
  selector: 'u-notification-sms-interrupt',
  templateUrl: './notification-sms-interrupt.component.html',
  styleUrls: ['./notification-sms-interrupt.component.scss'],
})
export class NotificationSmsInterruptComponent {
  readonly CHECK_ICON = faCheck;

  phoneNumber$: Observable<string> = this.store.select(NotificationSelectors.loginInterruptOptIn);

  data: { notification: Notification };
  optingIn = false;
  optInSuccess = false;
  error: string;

  constructor(private store: Store, private notificationService: NotificationService, private modal: NgbActiveModal) {}

  onClose() {
    this.store.dispatch(new MarkAsRead([this.data.notification]));
    this.modal.close();
  }

  consent() {
    this.optingIn = true;
    this.notificationService.loginInterruptOptIn().subscribe(
      (phone: SinglePhoneNumber) => {
        const country = this.store.selectSnapshot(UserProfileSelectors.getUser).country;

        const formattedPhoneNumber = this.formatPhoneNumber(phone.phoneNumber, country, true);
        this.store.dispatch(new UpdatePhoneNumber(formattedPhoneNumber));
        this.optInSuccess = true;
        this.optingIn = false;
      },
      (error) => {
        this.error = error;
      }
    );
  }

  private formatPhoneNumber(phoneValue: number | string, country: string, international?: boolean): any {
    try {
      const phoneNumber = parsePhoneNumber(phoneValue + '', country as CountryCode);
      if (international) {
        return phoneNumber.formatInternational();
      } else {
        return phoneNumber.formatNational();
      }
    } catch (error) {
      return phoneValue;
    }
  }
}
