import { Pipe, PipeTransform } from '@angular/core';
import { ReplacePipe } from '@shared/pipes/replace/replace.pipe';

@Pipe({
  name: 'interpolate',
})
export class InterpolatePipe implements PipeTransform {
  replacePipe = new ReplacePipe();

  transform(value: string, interpolateKeyValues: any): string {
    return this.interpolate(value, interpolateKeyValues);
  }

  interpolate(val: string, interpolateKeyValues: { [key: string]: string }) {
    if (val !== null && val !== undefined) {
      const bracketsStart = val.indexOf('{{');
      const bracketsEnd = val.indexOf('}}');
      if (bracketsEnd > -1 && bracketsStart > -1) {
        const variableName = val.substring(bracketsStart + 2, bracketsEnd);
        const variableProperties = variableName.split('.');
        const nestedValue = this.getNestedValues(variableProperties, interpolateKeyValues);
        const replaced = this.replacePipe.transform(val, '{{' + variableName + '}}', nestedValue);
        return this.interpolate(replaced, interpolateKeyValues);
      } else {
        return val;
      }
    }
  }

  getNestedValues(properties: string[], interpolateKeyValues: { [key: string]: string }) {
    let nestedValue;

    properties.forEach((prop) => {
      if (nestedValue && nestedValue[prop]) {
        nestedValue = nestedValue[prop];
      } else if (interpolateKeyValues[prop]) {
        nestedValue = interpolateKeyValues[prop];
      }
    });

    if (typeof nestedValue === 'object') {
      nestedValue = JSON.stringify(nestedValue);
    }

    return nestedValue || '';
  }
}
