import { Notification } from '@common/notifications/notification.model';

export interface NotificationStateModel {
  archivedNotifications: Notification[];
  currentNotifications: Notification[];
  interruptNotifications: Notification[];
  singleNotification: Notification;
  phoneNumber: string;
}

export const DEFAULT_NOTIFICATION_STATE = {
  archivedNotifications: [],
  currentNotifications: [],
  interruptNotifications: [],
  phoneNumber: '',
};
