<universal-modal-base>
  <ng-container modalHeader>
    <div class="row">
      <div [attr.id]="'dialog-auto-name' + data.notification.id" class="col-10 px-0">
        <h3>
          {{
            optInSuccess
              ? ('loginInterrupt.smsOptIn.successTitle' | translate)
              : ('loginInterrupt.smsOptIn.optInTitle' | translate)
          }}
        </h3>
      </div>
    </div>
  </ng-container>

  <ng-container modalBody>
    <alert type="danger" *ngIf="error">
      <div class="error-container" data-automation="error-container">
        <fa-icon [icon]="['fas', 'ban']" class="error-alert-icon"></fa-icon>
        {{ error | translate }}
      </div>
    </alert>
    <div class="notificationContent" [ngClass]="{ 'success-box': optInSuccess }">
      <div
        *ngIf="!optInSuccess"
        data-automation="sms-modal-body"
        [innerHtml]="'loginInterrupt.smsOptIn.body' | translate"
      ></div>
      <div *ngIf="optInSuccess" class="row" data-automation="success-box">
        <span class="success-icon col-2 col-sm-1">
          <fa-icon [icon]="['fas', 'check']" (click)="onClose()"></fa-icon>
        </span>

        <div
          class="col-10 col-sm-11 success-message"
          (click)="onClose()"
          [innerHtml]="
            'loginInterrupt.smsOptIn.successBody' | translate | interpolate : { phoneNumber: phoneNumber$ | async }
          "
        ></div>
      </div>
    </div>
  </ng-container>

  <ng-container modalFooter>
    <universal-modal-buttons *ngIf="!optInSuccess">
      <universal-button
        (click)="onClose()"
        [data]="{ type: 'tertiary', size: 'full', automationId: 'do-not-consent-button' }"
      >
        {{ 'loginInterrupt.smsOptIn.doNotConsent' | translate }}
      </universal-button>

      <universal-loading-button
        (click)="consent()"
        [data]="{ type: 'primary', automationId: 'consent-button' }"
        [loading]="optingIn"
      >
        {{ 'loginInterrupt.smsOptIn.consent' | translate }}
      </universal-loading-button>
    </universal-modal-buttons>

    <universal-modal-buttons *ngIf="optInSuccess">
      <universal-button (click)="onClose()" [data]="{ type: 'primary', automationId: 'close-button' }">
        {{ 'loginInterrupt.smsOptIn.close' | translate }}
      </universal-button>
    </universal-modal-buttons>
  </ng-container>
</universal-modal-base>
