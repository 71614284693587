import { Notification } from '@common/notifications/notification.model';
import { NotificationStateModel } from '@common/notifications/state/notification.state.model';
import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';

export const updateNotifications = (
  ctx: StateContext<NotificationStateModel>,
  updatedNotifications: Notification[]
) => {
  updatedNotifications.forEach((updatedNotification) => {
    ctx.setState(
      patch({
        archivedNotifications: updateItem(
          (notification) => notification.id === updatedNotification.id,
          updatedNotification
        ),
        currentNotifications: updateItem(
          (notification) => notification.id === updatedNotification.id,
          updatedNotification
        ),
        interruptNotifications: updateItem(
          (notification) => notification.id === updatedNotification.id,
          updatedNotification
        ),
      })
    );
  });
};

export const filterNotificationsByChannel = (notifications, channel) => {
  notifications = notifications.filter((notification) => notification.category.channel === channel);

  notifications.forEach((notification) => {
    notification.content = notification.content.filter((content) => content.channel === channel);
  });
  return notifications;
};
