import { Injectable } from '@angular/core';
import { NotificationService } from '@common/notifications/notification.service';
import {
  DeleteNotification,
  LoadArchivedNotifications,
  LoadCurrentNotifications,
  LoadLoginInterruptNotifications,
  LoadSingleNotification,
  MarkAsRead,
  MarkAsUnread,
  UpdatePhoneNumber,
} from '@common/notifications/state/notification.actions';
import {
  DEFAULT_NOTIFICATION_STATE,
  NotificationStateModel,
} from '@common/notifications/state/notification.state.model';
import {
  filterNotificationsByChannel,
  updateNotifications,
} from '@common/notifications/state/notification.state.utils';
import { Action, State, StateContext } from '@ngxs/store';
import cloneDeep from 'lodash/cloneDeep';
import { map } from 'rxjs/operators';

@State({
  name: 'notifications',
  defaults: DEFAULT_NOTIFICATION_STATE,
})
@Injectable()
export class NotificationState {
  constructor(private notificationService: NotificationService) {}

  @Action(LoadArchivedNotifications)
  getArchivedNotifications(ctx: StateContext<NotificationStateModel>, action: LoadArchivedNotifications) {
    return this.notificationService.getArchivedNotifications().pipe(
      map((archivedNotifications) => {
        const filteredNotifications = filterNotificationsByChannel(archivedNotifications, 'HUB');
        ctx.patchState({ archivedNotifications: filteredNotifications });
      })
    );
  }

  @Action(LoadCurrentNotifications)
  getCurrentNotification(ctx: StateContext<NotificationStateModel>, action: LoadCurrentNotifications) {
    return this.notificationService.getCurrentNotifications().pipe(
      map((currentNotifications) => {
        const filteredNotifications = filterNotificationsByChannel(currentNotifications, 'HUB');
        ctx.patchState({ currentNotifications: filteredNotifications });
      })
    );
  }

  @Action(LoadSingleNotification)
  getSingleNotification(ctx: StateContext<NotificationStateModel>, action: LoadSingleNotification) {
    return this.notificationService.getNotificationByConcatKey(action.key).pipe(
      map((singleNotification) => {
        ctx.patchState({ singleNotification });
      })
    );
  }

  @Action(LoadLoginInterruptNotifications)
  getInterruptNotification(ctx: StateContext<NotificationStateModel>, action: LoadLoginInterruptNotifications) {
    return this.notificationService.getLoginInterruptNotifications().pipe(
      map((interruptNotifications) => {
        const filteredNotifications = filterNotificationsByChannel(interruptNotifications, 'LOGIN_INTERRUPT');
        ctx.patchState({ interruptNotifications: filteredNotifications });
      })
    );
  }

  @Action(MarkAsRead)
  markNotificationsAsRead(ctx: StateContext<NotificationStateModel>, action: MarkAsRead) {
    const updatedNotifications = cloneDeep(action.notifications);

    updatedNotifications.forEach((notification) => {
      notification.read = true;
    });

    return this.notificationService.updateNotifications(updatedNotifications).pipe(
      map(() => {
        const filteredNotifications = filterNotificationsByChannel(updatedNotifications, 'HUB');
        updateNotifications(ctx, filteredNotifications);
      })
    );
  }

  @Action(MarkAsUnread)
  markNotificationsAsUnread(ctx: StateContext<NotificationStateModel>, action: MarkAsUnread) {
    const updatedNotifications = cloneDeep(action.notifications);

    updatedNotifications.forEach((notification) => {
      notification.read = false;
    });

    return this.notificationService.updateNotifications(updatedNotifications).pipe(
      map(() => {
        const filteredNotifications = filterNotificationsByChannel(updatedNotifications, 'HUB');
        updateNotifications(ctx, filteredNotifications);
      })
    );
  }

  @Action(DeleteNotification)
  deleteNotification(ctx: StateContext<NotificationStateModel>, action: DeleteNotification) {
    const { archivedNotifications } = cloneDeep(ctx.getState());
    const deletedNotification = { ...action.notification };

    deletedNotification.deleted = true;
    archivedNotifications.push(deletedNotification);

    archivedNotifications.sort((a, b) => a.millisSinceNotification - b.millisSinceNotification);

    const filteredNotifications = filterNotificationsByChannel([deletedNotification], 'HUB');
    ctx.patchState({ archivedNotifications: filteredNotifications });

    return this.notificationService
      .updateNotifications([deletedNotification])
      .pipe(map(() => updateNotifications(ctx, [deletedNotification])));
  }

  @Action(UpdatePhoneNumber)
  updatePhoneNumber({ patchState }: StateContext<NotificationStateModel>, action: UpdatePhoneNumber) {
    return patchState({ phoneNumber: action.phoneNumber });
  }
}
